import * as React from "react";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { validaCnpj } from "../../utils";
import TextField from "@mui/material/TextField";
import InputMask from "react-input-mask";
import { useForm, Controller } from "react-hook-form";
import { ptBR } from '@mui/x-date-pickers/locales';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/pt-br';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useQuery } from "../../hooks/useQuery";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { UsuarioEmpresaItem } from "../../components/UsuarioEmpresaItem";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';

export const PessoaJuridicaDetalhePage = () => {

    const { id } = useParams();
    const { getNaturezaJuridica, showCadastroFornecedor } = useQuery();
    const navigate = useNavigate();
    const [valueTab, setValueTab] = React.useState("1");
    const [fornecedor, setFornecedor] = React.useState();
    const [colaboradores, setColaboradores] = React.useState([]);
    const [naturezas, setNaturezas] = React.useState([]);
    const [status, setStatus] = React.useState(false);
    const [mask, setMask] = React.useState('(99) 99999-9999');
    const {
        handleSubmit,
        control,
        setValue,
        reset,
        setFocus,
        formState: { errors },
    } = useForm();

    React.useEffect(() => {
        console.log('id', id);
        if (id) {
            preencherFormulario();
        }
        listarNaturezajuridica();
    }, []);

    const preencherFormulario = async () => {
        try {
            const result = await showCadastroFornecedor(id);

            if (result && result.data && result.data.data) {
                setValue("cnpj", result.data.data.documento);
                setValue("cep", result.data.data.cep);
                setValue("endereco", result.data.data.endereco);
                setValue("numero", result.data.data.numero);
                setValue("complemento", result.data.data.complemento);
                setValue("bairro", result.data.data.bairro);
                setValue("cidade", result.data.data.cidade);
                setValue("estado", result.data.data.estado);
                setValue("razao_social", result.data.data.razao_social);
                setValue("nome_fantasia", result.data.data.razao_social);
                setValue("declaracao_enquadramento", result.data.data.declaracao_enquadramento);
                setValue("natureza_juridica", result.data.data.natureza_juridica);
                setValue("objeto_social", result.data.data.objeto_social);
                setValue("email", result.data.data.email);
                setValue("telefone", result.data.data.telefone);
                setValue("inscricao_estadual", result.data.data.inscricao_estadual);
                setValue("inscricao_municipal", result.data.data.inscricao_municipal);
                setStatus(result.data.data.status == 'A' ? true : false);
                setFornecedor(result.data.data);
                setColaboradores(result.data.data.colaboradores);
               
            }
        } catch (error) {
    
        }
    };

    const listarNaturezajuridica = async () => {
        const result = await getNaturezaJuridica();

        if (result && result.data) {
            setNaturezas(result.data.data);
        }
    }

    const handleChangeTab = (event, newValue) => {
        console.log(newValue);
        if (newValue == 1) {
            preencherFormulario();
        }
        setValueTab(newValue);
    };

    const UserTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
        fontSize: '18px !important',
    }));

    const onErrors = (error) => {

    }

    const onSubmit = (data) => {

    }

    const onChangeStatus = (event) => {
        setStatus(event.target.checked);
    }

    return (
        <Box style={{ width: '100%', margin: 0, padding: 0 }}>
            <Grid container spacing={5} paddingX="0" marginLeft={2}>
                <Grid md={12} xs={12} style={{ padding: '0 10px' }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => { navigate('/usuarios', { replace: true }) }}
                        edge="start"
                    >
                        <span
                            style={{ display: "flex", alignItems: 'center', color: '#005EB8', fontSize: 12, fontWeight: 'bold', fontFamily: "Campuni-Bold" }}
                        >
                            <ArrowBackIcon /> Voltar
                        </span>
                    </IconButton>
                </Grid>
                <Grid md={12} xs={12}>
                    <TabContext value={valueTab}>
                        <TabList variant="scrollable" value={valueTab} onChange={handleChangeTab} aria-label="lab API tabs example">
                            <UserTab label="Dados da empresa" value="1" />
                            <UserTab label="Usuários" value="2" />
                        </TabList>
                        <TabPanel value="1">
                            <form onSubmit={handleSubmit(onSubmit, onErrors)}>
                                <Grid container spacing={2}>
                                    <Grid container sm={12} item >
                                        <p style={{ fontSize: 18, color: '#666666' }}><b>{fornecedor && fornecedor.razao_social}</b></p>
                                    </Grid>
                                    <Grid md={7}>
                                        <Controller
                                            name="cnpj"
                                            disabled
                                            control={control}
                                            defaultValue={""}
                                            mask="99.999.999.9999-99"
                                            rules={{
                                                required: "campo é obrigatório",
                                                validate: (value) => {
                                                    return validaCnpj(value)
                                                }
                                            }}
                                            render={({
                                                field: { onChange, onBlur, ref, value },
                                                fieldState: { error },
                                            }) => (
                                                <InputMask
                                                    disabled
                                                    style={{ marginTop: '5px' }}
                                                    mask="99.999.999.9999-99"
                                                    maskChar={""}
                                                    value={value}
                                                    onBlur={onBlur}
                                                    onChange={(event) => {
                                                        onChange(event);
                                                    }}
                                                >
                                                    {(inputProps) => (
                                                        <TextField
                                                            disabled
                                                            margin="normal"
                                                            error={error}
                                                            helperText={error && error.message}
                                                            fullWidth
                                                            {...inputProps}
                                                            id="cnpj"
                                                            label="CNPJ"
                                                        />
                                                    )}
                                                </InputMask>
                                            )}
                                        />
                                        {errors.cnpj && errors.cnpj.type === "validate" && <small style={{ color: 'red' }}>CNPJ Inválido</small>}
                                    </Grid>
                                    <Grid md={5}>
                                        <Controller
                                            name="data_encerramento"
                                            // defaultValue={''}
                                            disabled
                                            control={control}
                                            render={({
                                                field: { value, onChange },
                                                fieldState: { error },
                                            }) => (
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br" localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}>
                                                    <Box style={{ paddingTop: 5, width: '100%' }}>

                                                        <DatePicker
                                                            disabled
                                                            // inputFormat="DD/MM/YYYY HH:mm"
                                                            defaultValue={null}
                                                            value={value}
                                                            label="Data de abertura"
                                                            // minDate={dayjs(new Date())}
                                                            onChange={(event) => {
                                                                onChange(event);
                                                            }}
                                                        />

                                                    </Box>
                                                </LocalizationProvider>
                                            )}
                                        />
                                    </Grid>
                                    <Grid md={12}>
                                        <Controller
                                            name="razao_social"
                                            rules={{
                                                required: "campo é obrigatório",
                                            }}
                                            disabled
                                            defaultValue={""}
                                            control={control}
                                            render={({
                                                field: { onChange, value },
                                                fieldState: { error },
                                            }) => (
                                                <TextField
                                                    style={{ marginTop: '5px' }}
                                                    margin="normal"
                                                    value={value}
                                                    disabled
                                                    error={error}
                                                    helperText={error && error.message}
                                                    onChange={onChange}
                                                    fullWidth
                                                    label="Razão Social"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid md={12}>
                                        <Controller
                                            name="nome_fantasia"
                                            rules={{
                                                required: "campo é obrigatório",
                                            }}
                                            disabled
                                            defaultValue={""}
                                            control={control}
                                            render={({
                                                field: { onChange, value },
                                                fieldState: { error },
                                            }) => (
                                                <TextField
                                                    style={{ marginTop: '5px' }}
                                                    margin="normal"
                                                    value={value}
                                                    disabled
                                                    error={error}
                                                    helperText={error && error.message}
                                                    onChange={onChange}
                                                    fullWidth
                                                    label="Nome Fantasia"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid md={5}>
                                        <Controller
                                            name="telefone"
                                            control={control}
                                            disabled
                                            defaultValue={""}
                                            rules={{ required: 'Telefone é obrigatório' }}
                                            mask={mask}
                                            render={({
                                                field: { onChange, ref, value },
                                                fieldState: { error },
                                            }) => (
                                                <InputMask
                                                    style={{ marginTop: '5px' }}
                                                    mask={mask}
                                                    disabled
                                                    maskChar={'_'}
                                                    onBlur={(e) => {
                                                        const v = e.target.value.replace(/\D/g, '');
                                                        if (v.length === 10) {
                                                            setMask('(99) 9999-9999');
                                                        } else {
                                                            setMask('(99) 99999-9999');
                                                        }
                                                    }}
                                                    onFocus={(e) => {
                                                        setMask('(99) 99999-9999');
                                                    }}
                                                    onChange={onChange}
                                                    value={value}
                                                >
                                                    {(inputProps) => (
                                                        <TextField
                                                            margin="normal"
                                                            disabled
                                                            error={error}
                                                            helperText={error && error.message}
                                                            fullWidth
                                                            {...inputProps}
                                                            id="telefone"
                                                            label="Telefone1"
                                                            placeholder="Digite o telefone com DDD"
                                                        />
                                                    )}
                                                </InputMask>
                                            )}
                                        />
                                    </Grid>
                                    <Grid md={7}>
                                        <Controller
                                            name="link"
                                            rules={{
                                                required: "campo é obrigatório",
                                            }}
                                            defaultValue={""}
                                            disabled
                                            control={control}
                                            render={({
                                                field: { onChange, value },
                                                fieldState: { error },
                                            }) => (
                                                <TextField
                                                    style={{ marginTop: '5px' }}
                                                    margin="normal"
                                                    value={value}
                                                    disabled
                                                    error={error}
                                                    helperText={error && error.message}
                                                    onChange={onChange}
                                                    fullWidth
                                                    label="Link do site"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid md={6}>
                                        <Controller
                                            name="inscricao_estadual"
                                            disabled
                                            control={control}
                                            defaultValue={""}
                                            render={({
                                                field: { onChange, value, onBlur },
                                                fieldState: { error },
                                            }) => (
                                                <InputMask
                                                    style={{ marginTop: '5px' }}
                                                    mask="99999999999999"
                                                    maskChar={''}
                                                    disabled
                                                    value={value}
                                                    onBlur={onBlur}
                                                    onChange={(event) => {
                                                        onChange(event);
                                                    }}
                                                >
                                                    {(inputProps) => (
                                                        <TextField
                                                            margin="normal"
                                                            disabled
                                                            error={error}
                                                            helperText={error && error.message}
                                                            fullWidth
                                                            {...inputProps}
                                                            id="inscricao_estadual"
                                                            label="Incrição Estadual"
                                                        />
                                                    )}
                                                </InputMask>
                                            )}
                                        />
                                    </Grid>
                                    <Grid md={6}>
                                        <Controller
                                            name="inscricao_municipal"
                                            control={control}
                                            disabled
                                            defaultValue={""}
                                            render={({
                                                field: { onChange, value, onBlur },
                                                fieldState: { error },
                                            }) => (
                                                <InputMask
                                                    style={{ marginTop: '5px' }}
                                                    mask="999.999.999/9999"
                                                    maskChar={''}
                                                    disabled
                                                    value={value}
                                                    onBlur={onBlur}
                                                    onChange={(event) => {
                                                        onChange(event);
                                                    }}
                                                >
                                                    {(inputProps) => (
                                                        <TextField
                                                            margin="normal"
                                                            disabled
                                                            error={error}
                                                            helperText={error && error.message}
                                                            fullWidth
                                                            {...inputProps}
                                                            id="inscricao_municipal"
                                                            label="Incrição Municipal"
                                                        />
                                                    )}
                                                </InputMask>
                                            )}
                                        />
                                    </Grid>
                                    <Grid md={12}>
                                        <Controller
                                            name="natureza_juridica"
                                            id="natureza_juridica"
                                            disabled
                                            defaultValue={0}
                                            control={control}
                                            render={({
                                                field: { onChange, value },
                                                fieldState: { error },
                                            }) => (
                                                <TextField
                                                    value={value}
                                                    style={{ height: 38, marginTop: 5 }}
                                                    error={error}
                                                    disabled
                                                    label="Natureza Juridica"
                                                    select
                                                    className="inputSelect"
                                                    onChange={(e) => {
                                                        onChange(e);
                                                    }}
                                                    fullWidth
                                                >
                                                    <MenuItem disabled value={0}>
                                                        Selecione a Natureza Juridica
                                                    </MenuItem>
                                                    {naturezas && naturezas.map((res, index) =>
                                                        <MenuItem value={res.valor}>
                                                            {`${res.cod} ${res.descricao}`}
                                                        </MenuItem>
                                                    )}
                                                </TextField>
                                            )}
                                        />
                                    </Grid>
                                    <Grid md={12}>
                                        <FormControlLabel value={status} control={<Switch checked={status} value={status} onChange={onChangeStatus} />} label={status ? "Ativa" : "Desativado"} />
                                    </Grid>
                                    <Grid md={5}>
                                        <Controller
                                            name="cep"
                                            defaultValue={""}
                                            disabled
                                            control={control}
                                            render={({
                                                field: { onChange, value },
                                                fieldState: { error },
                                            }) => (
                                                <TextField
                                                    style={{ marginTop: '5px' }}
                                                    margin="normal"
                                                    disabled
                                                    value={value}
                                                    error={error}
                                                    helperText={error && error.message}
                                                    onChange={onChange}
                                                    fullWidth
                                                    label="CEP"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid md={7}></Grid>
                                    <Grid md={12}>
                                        <Controller
                                            name="endereco"
                                            defaultValue={""}
                                            disabled
                                            control={control}
                                            render={({
                                                field: { onChange, value },
                                                fieldState: { error },
                                            }) => (
                                                <TextField
                                                    style={{ marginTop: '5px' }}
                                                    margin="normal"
                                                    value={value}
                                                    disabled
                                                    error={error}
                                                    helperText={error && error.message}
                                                    onChange={onChange}
                                                    fullWidth
                                                    label="Logradouro"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid md={3}>
                                        <Controller
                                            name="numero"
                                            defaultValue={""}
                                            control={control}
                                            disabled
                                            render={({
                                                field: { onChange, value },
                                                fieldState: { error },
                                            }) => (
                                                <TextField
                                                    style={{ marginTop: '5px' }}
                                                    margin="normal"
                                                    value={value}
                                                    disabled
                                                    type="number"
                                                    error={error}
                                                    helperText={error && error.message}
                                                    onChange={onChange}
                                                    fullWidth
                                                    label="Número"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid md={4}>
                                        <Controller
                                            name="bairro"
                                            defaultValue={""}
                                            control={control}
                                            disabled
                                            render={({
                                                field: { onChange, value },
                                                fieldState: { error },
                                            }) => (
                                                <TextField
                                                    style={{ marginTop: '5px' }}
                                                    margin="normal"
                                                    value={value}
                                                    onChange={onChange}
                                                    disabled
                                                    fullWidth
                                                    label="Bairro"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid md={4}>
                                        <Controller
                                            name="complemento"
                                            control={control}
                                            disabled
                                            defaultValue={""}
                                            render={({
                                                field: { onChange, value },
                                                fieldState: { error },
                                            }) => (
                                                <TextField
                                                    style={{ marginTop: '5px' }}
                                                    margin="normal"
                                                    disabled
                                                    value={value}
                                                    onChange={onChange}
                                                    fullWidth
                                                    label="Complemento"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid md={6}>
                                        <Controller
                                            name="estado"
                                            control={control}
                                            defaultValue={"MG"}
                                            disabled
                                            // rules={{
                                            //     required: "campo é obrigatório",
                                            // }}
                                            render={({
                                                field: { onChange, value },
                                                fieldState: { error },
                                            }) => (
                                                <TextField
                                                    style={{ marginTop: '5px' }}
                                                    margin="normal"
                                                    disabled
                                                    value={value}
                                                    onChange={onChange}
                                                    fullWidth
                                                    label="Estado"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid md={6}>
                                        <Controller
                                            name="cidade"
                                            disabled
                                            defaultValue={""}
                                            control={control}
                                            render={({
                                                field: { onChange, value },
                                                fieldState: { error },
                                            }) => (
                                                <TextField
                                                    style={{ marginTop: '5px' }}
                                                    margin="normal"
                                                    value={value}
                                                    disabled
                                                    error={error}
                                                    helperText={error && error.message}
                                                    onChange={onChange}
                                                    fullWidth
                                                    label="Cidade"
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </form>
                        </TabPanel>
                        <TabPanel value="2">
                            {colaboradores && colaboradores.map(c => {
                                return (
                                    <UsuarioEmpresaItem fornecedor={fornecedor} item={c} atualizaLista={preencherFormulario} />
                                )
                            })}
                          
                        </TabPanel>
                    </TabContext>
                </Grid>
                <Grid md={2} xs={0}></Grid>
            </Grid>
        </Box >
    )
}