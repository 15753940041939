import * as React from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate } from "react-router-dom";
import { CircularProgress, SvgIcon } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { useQuery } from "../hooks/useQuery";
import TableCell from '@material-ui/core/TableCell';
import Modal from '@mui/material/Modal';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import TableRow from '@material-ui/core/TableRow';
import dayjs from "dayjs";
import { isMobile } from "react-device-detect";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '100%' : '60%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    paddingLeft: 4,
    paddingRight: 4
};

export const DocumentoItem = ({ item, setDocumentos, id }) => {

    const navigate = useNavigate();
    const { aprovarReprovarDocumento, listarDocumentosPorUser } = useQuery();
    const [loadingRow, setLoadingRow] = React.useState();
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [justificativa, setJustificativa] = React.useState();
    const url = process.env.REACT_APP_API_BASE_URL;

    const svgCheck = () => {
        return (
            <SvgIcon style={{ height: 17, width: 17 }}>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.7188 9C17.7188 13.8152 13.8152 17.7188 9 17.7188C4.18475 17.7188 0.28125 13.8152 0.28125 9C0.28125 4.18475 4.18475 0.28125 9 0.28125C13.8152 0.28125 17.7188 4.18475 17.7188 9ZM7.99151 13.6165L14.4603 7.14776C14.6799 6.9281 14.6799 6.57193 14.4603 6.35228L13.6648 5.5568C13.4451 5.33711 13.089 5.33711 12.8693 5.5568L7.59375 10.8323L5.13074 8.36926C4.91108 8.14961 4.55491 8.14961 4.33522 8.36926L3.53974 9.16474C3.32009 9.3844 3.32009 9.74057 3.53974 9.96022L7.19599 13.6165C7.41568 13.8362 7.77182 13.8362 7.99151 13.6165Z" fill="#6BAC54" />
                </svg>
            </SvgIcon>
        )
    }


    const svgUndo = () => {

        return (
            <SvgIcon style={{ height: 17, width: 17 }}>
                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.48149 2.78828C0.0757799 6.194 0.0757799 11.7127 3.48149 15.1185C6.88721 18.5242 12.406 18.5242 15.8117 15.1185C19.2174 11.7127 19.2174 6.194 15.8117 2.78828C12.406 -0.617431 6.88721 -0.617431 3.48149 2.78828ZM13.9224 6.0697C14.0864 6.23377 14.0864 6.50225 13.9224 6.66632L11.6353 8.95337L13.9224 11.2404C14.0864 11.4045 14.0864 11.673 13.9224 11.837L12.5303 13.2292C12.3662 13.3932 12.0977 13.3932 11.9336 13.2292L9.64658 10.9421L7.35953 13.2292C7.19546 13.3932 6.92698 13.3932 6.76291 13.2292L5.37079 11.837C5.20672 11.673 5.20672 11.4045 5.37079 11.2404L7.65784 8.95337L5.37079 6.66632C5.20672 6.50225 5.20672 6.23377 5.37079 6.0697L6.76291 4.67758C6.92698 4.51351 7.19546 4.51351 7.35953 4.67758L9.64658 6.96463L11.9336 4.67758C12.0977 4.51351 12.3662 4.51351 12.5303 4.67758L13.9224 6.0697Z" fill="#F4455A" />
                </svg>
            </SvgIcon>
        )
    }

    const svgRedo = () => {

        return (
            <SvgIcon style={{ height: 17, width: 17 }}>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_1841_808)">
                        <path d="M0.410273 2.68798e-07H2.07703C2.13416 -6.42177e-05 2.1907 0.011475 2.24324 0.0339182C2.29578 0.0563614 2.34321 0.089241 2.38265 0.130563C2.4221 0.171885 2.45275 0.220789 2.47273 0.274309C2.49271 0.327829 2.50162 0.38485 2.4989 0.441914L2.35828 3.35145C3.17589 2.38798 4.1936 1.61423 5.34061 1.08401C6.48762 0.5538 7.73637 0.279859 9 0.28125C13.8045 0.28125 17.7223 4.20223 17.7187 9.00668C17.7152 13.8189 13.8129 17.7187 9 17.7187C6.84104 17.7218 4.7583 16.9208 3.15773 15.4719C3.11497 15.4336 3.08047 15.3871 3.05634 15.335C3.0322 15.2829 3.01894 15.2265 3.01736 15.1692C3.01578 15.1118 3.02592 15.0547 3.04715 15.0014C3.06839 14.9481 3.10027 14.8997 3.14086 14.8591L4.33617 13.6638C4.41182 13.5882 4.51337 13.5441 4.62028 13.5405C4.72719 13.5369 4.83148 13.5741 4.91203 13.6445C5.85651 14.4755 7.03185 14.9988 8.28136 15.1447C9.53086 15.2907 10.7952 15.0523 11.9057 14.4613C13.0163 13.8704 13.9203 12.955 14.4974 11.8371C15.0744 10.7193 15.297 9.45209 15.1354 8.2045C14.9739 6.95692 14.4359 5.78821 13.5932 4.85417C12.7505 3.92014 11.6431 3.26516 10.4187 2.97655C9.19426 2.68794 7.91093 2.77941 6.7398 3.23877C5.56867 3.69812 4.56537 4.50354 3.86367 5.54766L7.43308 5.37644C7.49015 5.37373 7.54717 5.38264 7.60069 5.40262C7.65421 5.4226 7.70311 5.45325 7.74444 5.4927C7.78576 5.53214 7.81864 5.57957 7.84108 5.63211C7.86352 5.68464 7.87506 5.74119 7.875 5.79832V7.46508C7.875 7.57697 7.83055 7.68427 7.75143 7.76339C7.67232 7.84251 7.56501 7.88695 7.45312 7.88695H0.410273C0.298384 7.88695 0.191078 7.84251 0.111961 7.76339C0.0328445 7.68427 -0.0116043 7.57697 -0.0116043 7.46508V0.421875C-0.0116043 0.309987 0.0328445 0.202681 0.111961 0.123565C0.191078 0.0444477 0.298384 2.68798e-07 0.410273 2.68798e-07Z" fill="#292D32" />
                    </g>
                    <defs>
                        <clipPath id="clip0_1841_808">
                            <rect width="18" height="18" fill="white" transform="matrix(-1 0 0 1 18 0)" />
                        </clipPath>
                    </defs>
                </svg>
            </SvgIcon>
        )
    }

    const svgAlert = () => {

        return (
            <SvgIcon style={{ width: 61, height: 65 }}>
                <svg width="61" height="55" viewBox="0 0 61 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M60.3135 46.5986C62.2682 49.9869 59.8146 54.2222 55.9103 54.2222H5.08908C1.17735 54.2222 -1.26498 49.9803 0.685963 46.5986L26.0969 2.54008C28.0526 -0.849763 32.9509 -0.843621 34.9031 2.54008L60.3135 46.5986ZM30.5 37.4896C27.8096 37.4896 25.6285 39.6707 25.6285 42.3611C25.6285 45.0516 27.8096 47.2326 30.5 47.2326C33.1905 47.2326 35.3716 45.0516 35.3716 42.3611C35.3716 39.6707 33.1905 37.4896 30.5 37.4896ZM25.8749 19.979L26.6605 34.3818C26.6973 35.0557 27.2545 35.5833 27.9294 35.5833H33.0706C33.7455 35.5833 34.3028 35.0557 34.3395 34.3818L35.1251 19.979C35.1648 19.251 34.5852 18.6389 33.8562 18.6389H27.1438C26.4147 18.6389 25.8352 19.251 25.8749 19.979Z" fill="#CCCCCC" />
                </svg>
            </SvgIcon>
        )
    }

    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    }

    const getDocumentos = async () => {

        setLoadingRow(true);
        const result = await listarDocumentosPorUser(id, null, 1);
        if (result && result.data) {
            setDocumentos(result.data.data);
        }

        setLoadingRow(false);
    }

    const atualizaDocumento = async (id, status) => {
        setOpenConfirm(false)
        const result = await aprovarReprovarDocumento(id, { status: status, motivo: justificativa });
        if (result && result.data) {
            getDocumentos();
        }
    }


    return (
        <TableRow>
            <TableCell>{item.tipo_documento.nome}</TableCell>
            <TableCell>{item && item.area && item.area.nome}</TableCell>
            <TableCell>{item && item.processo && item.processo.titulo}</TableCell>
            <TableCell component="th" scope="row">
                {dayjs(item.created_at).format('DD/MM/YYYY HH:mm')}
            </TableCell>
            {loadingRow ? (
                <TableCell> <CircularProgress /></TableCell>
            ) : (
                <TableCell>
                    <IconButton onClick={() => {
                        
                        window.open(`${url}/storage/${item.documento}`, '_blank').focus();
                    }}>
                        <span style={{ color: "#005EB8" }}>Visualizar</span>
                    </IconButton>
                    {item.status == 'A' ? (
                        <Button style={{ background: '#DFDFDF', height: 30, width: 110, borderRadius: 10 }}>
                            <span style={{ color: "#6BAC54", marginRight: 5 }}>Aprovado</span>
                            {svgCheck()}
                        </Button>
                    ) : null}
                    {item.status == 'R' ? (
                        <Button style={{ background: '#DFDFDF', height: 30, width: 110, borderRadius: 10 }}>
                            <span style={{ color: "#F4455A", marginRight: 5 }}>Reprovado</span>
                            {svgUndo()}
                        </Button>
                    ) : null}
                    {item.status == 'P' || item.status == 'E' ? (
                        <>
                            <IconButton onClick={() => { atualizaDocumento(item.id, 'A', '') }}>
                                <span style={{ color: "#6BAC54" }}>Aprovar</span>
                            </IconButton>
                            <IconButton onClick={() => { setOpenConfirm(true)}}>
                                <span style={{ color: "#F4455A" }}>Reprovar</span>
                            </IconButton>
                        </>
                    ) : (
                        <Button onClick={() => { atualizaDocumento(item.id, 'E') }} style={{ background: '#DFDFDF', height: 30, width: 110, borderRadius: 10, marginLeft: 2 }}>
                            <span style={{ color: "#292D32", marginRight: 5 }}>Desfazer</span>
                            {svgRedo()}
                        </Button>
                    )}

                </TableCell>
            )}
             <Modal
                open={openConfirm}
                onClose={handleCloseConfirm}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid container spacing={1} marginBottom={5}>
                        <Grid md={12} display={'flex'} justifyContent={'center'} marginTop={5}>
                            {svgAlert()}
                        </Grid>
                        <Grid md={12}>
                            <p style={{ fontSize: 18, color: '#666666', fontWeight: '500', textAlign: 'center' }}>Escreva sua justificativa para recusar o documento.</p>
                        </Grid>
                        <Grid md={12}>
                            <TextField
                                style={{ marginTop: '5px' }}
                                margin="normal"
                                value={justificativa}
                                onChange={(event) => { setJustificativa(event.target.value) }}
                                multiline
                                rows={3}
                                fullWidth
                                label="Justificativa"
                            />
                        </Grid>
                        <Grid md={12} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <IconButton
                                color="inherit"
                                style={{ height: 24, width: 100 }}
                                aria-label="open drawer"
                                onClick={() => setOpenConfirm(false)}
                                edge="start"
                            >
                                <span style={{ fontSize: 12, color: '#F4455A', fontWeight: '700' }}>Cancelar</span>
                            </IconButton>
                            <Button
                                onClick={() => {
                                    atualizaDocumento(item.id, 'R', justificativa) 
                                }}
                                disabled={justificativa == undefined || justificativa == ""}
                                style={{ width: '100px', height: 41 }}
                                variant="contained"

                            >
                                {loading ? (
                                    <CircularProgress size={20} />
                                ) : null}

                                Recusar
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal >

        </TableRow>
    )
}